import React, { ReactElement } from 'react'
import { navigate } from 'gatsby'
import { makeStyles, Box } from '@material-ui/core'
import Pagination, { PaginationProps } from '@material-ui/lab/Pagination'
import { ArticlePage } from '@/types/articlepage'

import * as queryString from 'querystring'

const useStyles = makeStyles((theme) => ({
  paginator: {
    '& ul': {
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    '& .MuiPaginationItem-icon': {
      color: theme.palette.error.main,
    },
    '& .MuiPaginationItem-root.Mui-selected': {
      height: 28,
      minWidth: 28,
      borderRadius: '50% !important',
      '&:hover': {
        color: theme.palette.text.default,
      },
    },
    '& .MuiPagination-ul > li:last-child .MuiPaginationItem-root': {
      '&:focus': {
        border: `2px solid ${theme.palette.text.primary}`,
        backgroundColor: theme.palette.background.accent,
      },
    },
    '& .MuiPagination-ul > li:first-child .MuiPaginationItem-root': {
      '&:focus': {
        border: `2px solid ${theme.palette.text.primary}`,
        backgroundColor: theme.palette.background.accent,
      },
    },
    '@media (prefers-contrast: more)': {
      '& .MuiPagination-ul > li .MuiPaginationItem-root': {
        '&:focus': {
          border: `2px solid ${theme.palette.text.hint}`,
          backgroundColor: theme.palette.background.grey,
        },
      },
      '& .MuiPagination-ul > li:last-child .MuiPaginationItem-root': {
        border: '2px solid rgba(0,0,0,.2)',
      },
      '& .MuiPagination-ul > li:first-child .MuiPaginationItem-root': {
        border: '2px solid rgba(0,0,0,.2)',
      },
    },
  },
}))

//die Props müssen noch typisiert werden.

export type updatePaginationProps = {
  page: number
  currentCategory?: RvG.Contentful.ICategory
  perPage?: number
  currentYearId?: string | undefined
  filteredArticles?: Array<ArticlePage>
  setPaginatedArticles?: React.Dispatch<React.SetStateAction<ArticlePage[]>>
  filteredMediaItems?:
    | RvG.Contentful.MediaListImageListContentfulYouTubeItemUnion[]
    | undefined

  filteredPressItems?: Array<ArticlePage>
  typeFilter?: string
  setPaginatedMediaItems?: React.Dispatch<
    React.SetStateAction<
      RvG.Contentful.MediaListImageListContentfulYouTubeItemUnion[] | undefined
    >
  >
  setPaginatedPressItems?: React.Dispatch<
    React.SetStateAction<ArticlePage[] | undefined>
  >
}

export const updatePagination = ({
  page,
  currentCategory,
  perPage,
  currentYearId,
  filteredArticles,
  setPaginatedArticles,
  filteredMediaItems,
  filteredPressItems,
  typeFilter,
  setPaginatedMediaItems,
  setPaginatedPressItems,
}: updatePaginationProps) => {
  if (!page) return
  const offset = (page - 1) * perPage

  if (!!filteredArticles) {
    const filterCategoryId = currentCategory.id
    const filterYearId = currentYearId
    const filterPage = page
    const urlObject = {
      ...(filterCategoryId !== '0' && { filterCategoryId }),
      ...(filterYearId && { filterYearId }),
      filterPage,
    }
    navigate(`${location.pathname}#${queryString.stringify(urlObject)}`, {
      replace: true,
      state: {
        preventScroll: true,
      },
    })
    const currentPageArticles = filteredArticles.slice(offset, offset + perPage)
    setPaginatedArticles(currentPageArticles)
  }

  if (!!filteredPressItems) {
    const filterYearId = currentYearId
    const filterPage = page
    const urlObject = {
      ...(typeFilter !== 'media' && filterYearId && { filterYearId }),
      filterPage,
    }
    if (filteredMediaItems) {
      const currentMediaItems = filteredMediaItems.slice(
        offset,
        offset + perPage
      )
      setPaginatedMediaItems(currentMediaItems)
    } else {
      setPaginatedMediaItems([])
    }

    navigate(`${location.pathname}#${queryString.stringify(urlObject)}`, {
      replace: true,
      state: {
        preventScroll: true,
      },
    })
    const currentPressItems = filteredPressItems.slice(offset, offset + perPage)
    setPaginatedPressItems(currentPressItems)
  }
}

export type PaginatorProps = RvG.IReactDefaultProps & PaginationProps

export default function Paginator({
  className,
  ...props
}: PaginatorProps): ReactElement {
  const classes = useStyles()

  return (
    <Box className={className}>
      <Pagination
        className={classes.paginator}
        data-testid="pagination"
        defaultPage={1}
        color="primary"
        size="medium"
        {...props}
      />
    </Box>
  )
}
